
import authenticationService from "@/core/services/AuthenticationService";

export default {
  beforeMount() {
    authenticationService.logout();
  },
  data() {
    return {
      title: 'Logging Out...',
      message: 'Please wait while your account is logged out.',
      // Clever: https://clever.com/oauth/authorize?response_type=code&client_id=cae423b6528d6b18a35c&redirect_uri=https%3A%2F%2Farcid.americanreading.com%2Foauth%2Fclever%2Farcreads
      // Classlink: https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&client_id=c16578058494570174e98cfa07ef4d69235551ea1b07&response_type=code&redirect_uri=https%3A%2F%2Farcid.americanreading.com%2Foauth%2Fclasslink%2Farcreads
      /* TODO: Let's move these host names to environment variables instead of hard-coding. */
      cleverSSOURL: 'https://clever.com/oauth/authorize?response_type=code&client_id=cae423b6528d6b18a35c&redirect_uri='+process.env.VUE_APP_ARCID_HOST+'%2Foauth%2Fclever%2Farcreads',
      classlinkSSOURL: 'https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&client_id=c16578058494570174e98cfa07ef4d69235551ea1b07&response_type=code&redirect_uri='+process.env.VUE_APP_ARCID_HOST+'%2Foauth%2Fclasslink%2Farcreads'
    }
  },
}
